import { Injectable } from '@angular/core';
import axios from 'axios';

@Injectable({
  providedIn: 'root'
})
export class AxiosService {

    AUTH_KEY = "auth_token";
    USER_KEY = "user_key";
   
  constructor() {
    axios.defaults.baseURL = 'api';
    axios.defaults.headers.post['Content-Type'] = 'application/json';
  }

  

  request(method: string, url: string, data: any): Promise<any> {
      let headers: any = {};

      if (this.getAuthToken() !== null) {
          headers = {"Authorization": "Bearer " + this.getAuthToken()};
      }

      return axios({
          method: method,
          url: url,
          data: data,
          headers: headers
      });

      
  }
  

  

  requestWithoutTokens(method: string, url: string, data: any): Promise<any> {

    return axios({
        method: method,
        url: url,
        data: data
    });
}

  getAuthToken(): string | null {
    return typeof window !== "undefined" && window !== undefined && window != null ? window.localStorage.getItem(this.AUTH_KEY) : null;
  }




}